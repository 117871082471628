import { createSelector } from 'reselect'
import { find, groupBy, reduce, uniq } from 'lodash'
import selectSiteRates from './selectSiteRates'
import selectAreaMaterialTypes from './selectAreaMaterialTypes'
import commonSymbols from '../Utils/commonSymbols'

const selectMaterialTypes = state => state.core.materialTypes
const selectMaterialRanges = state => state.core.materialRanges
const selectOrder = state => state.screens.CostSheet.order

export default createSelector(
  selectMaterialTypes,
  selectMaterialRanges,
  selectOrder,
  selectSiteRates,
  selectAreaMaterialTypes,
  (materialTypes, materialRanges, order) => {

    const areas = order.cost_sheet.cost_sheet_areas

    const areasGroupedByRange = groupBy(areas, row => `${row.material_type_id || 0}|${row.material_range_id || 0}`)
    const areasGroupedByVariant = groupBy(areas, row => `${row.material_type_id || 0}|${row.material_range_id || 0}|${row.range_variant_id || 0}`)

    const areaSummary = {
      takeoff: [],
      materialTypes: [],
      materialRanges: [],
      rangeVariants: [],

      squareMetresNet: 0,
      squareMetresGross: 0,
      squareMetres: 0,

      materialTypeSymbolMap: {},
    }

    const symbols = uniq(areas.filter(row => !!row.area).map(row => row.area.name.replace(/[a-zA-Z0-9]/g, '').trim()).filter(symbol => symbol !== ''))

    commonSymbols.forEach(symbol => {
      if (!symbols.includes(symbol)) {
        symbols.push(symbol)
      }
    })

    materialTypes.forEach((materialType) => {

      areaSummary.materialTypeSymbolMap[materialType.id] = {
        squareMetresNet: 0,
        squareMetresGross: 0,
      }

      symbols.forEach(symbol => {
        areaSummary.materialTypeSymbolMap[materialType.id][symbol] = { squareMetresNet: 0, squareMetresGross: 0 }
        areaSummary.materialTypeSymbolMap[materialType.id][`/${symbol}`] = { squareMetresNet: 0, squareMetresGross: 0 }
      })

    })

    areas.forEach(({ area, material_range: materialRange }) => {

      if (!area || !materialRange || !area.square_metres) return

      const squareMetresNet = area.square_metres
      let squareMetresGross = area.square_metres_gross

      if (materialRange.wastage_perc) {
        squareMetresGross = squareMetresNet + ((squareMetresNet / 100) * materialRange.wastage_perc)
      }

      areaSummary.takeoff.push({ area, squareMetresNet, squareMetresGross })
      
      areaSummary.squareMetresNet += squareMetresNet
      areaSummary.squareMetresGross += squareMetresGross

      areaSummary.materialTypeSymbolMap[materialRange.material_type_id].squareMetresNet += squareMetresNet
      areaSummary.materialTypeSymbolMap[materialRange.material_type_id].squareMetresGross += squareMetresGross

      symbols.forEach(symbol => {
        if (area.name.includes(symbol)) {
          areaSummary.materialTypeSymbolMap[materialRange.material_type_id][symbol].squareMetresNet += squareMetresNet
          areaSummary.materialTypeSymbolMap[materialRange.material_type_id][symbol].squareMetresGross += squareMetresGross
        } else {
          areaSummary.materialTypeSymbolMap[materialRange.material_type_id][`/${symbol}`].squareMetresNet += squareMetresNet
          areaSummary.materialTypeSymbolMap[materialRange.material_type_id][`/${symbol}`].squareMetresGross += squareMetresGross
        }
      })

    })

    Object.keys(areasGroupedByRange).forEach(key => {

      const [materialTypeId, materialRangeId] = key.split('|').map(id => Number(id))

      if (!materialTypeId || !materialRangeId) return

      const materialType = find(materialTypes, row => row.id === materialTypeId)
      const materialRange = find(materialRanges, row => row.id === materialRangeId)

      let squareMetres = reduce(areasGroupedByRange[key], (carry, area) => carry + (area.square_metres || 0), 0)
      const areaSquareMetres = squareMetres // keep a record of this prior to pack rounding below

      // If this is a 'pack', we'll convert the square metres into the necessary pack amount
      if (materialType.usage_key === 'square-metres') {
        squareMetres = Math.ceil(squareMetres / materialRange.square_metres) * materialRange.square_metres
      }

      areaSummary.materialRanges.push({
        materialTypeId,
        materialRangeId,
        squareMetres,
        areaSquareMetres,
        materialType,
        materialRange,
      })

    })

    Object.keys(areasGroupedByVariant).forEach(key => {

      const [materialTypeId, materialRangeId, rangeVariantId] = key.split('|').map(id => Number(id))

      if (!materialTypeId || !materialRangeId) return

      const materialType = find(materialTypes, row => row.id === materialTypeId)
      const materialRange = find(materialRanges, row => row.id === materialRangeId)
      const rangeVariant = find(materialRange.range_variants, row => row.id === rangeVariantId)

      let squareMetres = reduce(areasGroupedByVariant[key], (carry, area) => carry + (area.square_metres || 0), 0)
      const areaSquareMetres = squareMetres // keep a record of this prior to pack rounding below

      // If this is a 'pack', we'll convert the square metres into the necessary pack amount
      if (materialType.usage_key === 'square-metres') {
        squareMetres = Math.ceil(squareMetres / materialRange.square_metres) * materialRange.square_metres
      }

      areaSummary.rangeVariants.push({
        materialTypeId,
        materialRangeId,
        rangeVariantId,
        squareMetres,
        areaSquareMetres,
        materialType,
        materialRange,
        rangeVariant,
      })

    })

    const rangeVariantsGrouped = groupBy(areaSummary.rangeVariants, 'materialTypeId')

    Object.keys(rangeVariantsGrouped).forEach(materialTypeId => {

      const materialType = find(materialTypes, row => row.id === Number(materialTypeId))

      const $squareMetres = reduce(rangeVariantsGrouped[materialTypeId], (carry, { squareMetres }) => carry + squareMetres, 0)
      const $areaSquareMetres = reduce(rangeVariantsGrouped[materialTypeId], (carry, { areaSquareMetres }) => carry + areaSquareMetres, 0)

      areaSummary.materialTypes.push({
        materialTypeId: materialType.id,
        squareMetres: $squareMetres,
        areaSquareMetres: $areaSquareMetres,
        materialType,
      })

      if (materialType.id === order.cost_sheet.material_type_id) {
        areaSummary.squareMetres = $squareMetres
      }

    })

    return areaSummary

  },
)
